<template>
  <div>
    <el-dialog
      title="调整手续费1"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div>
          <TextInput
            :config="{
              label: '税后手续费',
              modelKey: 'name',
              type: 'input',
              width: '100%',
              isNum: true,
            }"
            v-model="dataForm.name"
            ><span slot="inputAppend">%</span></TextInput
          >
          <TextInput
            :config="{
              label: '个人佣金费率',
              modelKey: 'name',
              type: 'input',
              width: '100%',
              isNum: true,
            }"
            v-model="dataForm.name"
            ><span slot="inputAppend">%</span></TextInput
          >
          <TextInput
            :config="{
              label: '公估服务费率',
              modelKey: 'name',
              type: 'input',
              width: '100%',
              isNum: true,
            }"
            v-model="dataForm.name"
          >
            <span slot="inputAppend">%</span>
          </TextInput>
          <TextInput
            :config="{
              label: '公司保留费率',
              modelKey: 'name',
              type: 'input',
              width: '100%',
              isNum: true,
            }"
            v-model="dataForm.name"
            ><span slot="inputAppend">%</span></TextInput
          >
          <TextInput
            :config="{
              label: '部门保留费率',
              modelKey: 'name',
              type: 'input',
              width: '100%',
              isNum: true,
            }"
            v-model="dataForm.name"
            ><span slot="inputAppend">%</span></TextInput
          >
        </div>
        <!-- 团队长费率表格 -->
        <div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="团队长名称">
              <template slot-scope="scope">
                <TextInput
                  isText
                  :config="{
                    type: 'input',
                    width: '100%',
                    isNum: true,
                  }"
                  v-model="dataForm.name"
                ></TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="团队长费率"
              ><template slot-scope="scope">
                <TextInput
                  :config="{
                    type: 'input',
                    width: '100%',
                    isNum: true,
                  }"
                  v-model="dataForm.name"
                  ><span slot="inputAppend">%</span></TextInput
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
export default {
  name: "RateAdjustmentDialog",
  components: {
    TextInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  data() {
    return {
      dialogVisible: true,
      dictList: {
        //是否禁用字典
        isDisabled: [
          { label: "是", value: "1" },
          { label: "否", value: "0" },
        ],
      },
      dataForm: { name: "" },
      tableData: [
        {
          date1: 1,
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
        {
          date1: "团长费率列",
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
        {
          date1: 1,
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
        {
          date1: "团长费率列",
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
        {
          date1: 1,
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
        {
          date1: "团长费率列",
          date2: 2,
          date3: 3,
          date4: 4,
          date5: 5,
          date6: 6,
          date7: "action",
        },
      ],
    };
  },
  methods: {
    handleClose(done) {
      this.clearForm();
      done();
    },
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    submitForm() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .inner-table {
  .el-table__header-wrapper {
    display: none !important;
  }
}
::v-deep .row-span {
  background-color: aqua;
  padding: unset;
}
</style>
